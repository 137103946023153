<template>
  <v-card
    :disabled="loading"
    :loading="loading"
    class="mx-auto"
    :max-width="394"
    :min-width="$vuetify.display.smAndDown ? '100%' : '270px'"
  >
    <!-- Loading Slot -->
    <template v-slot:loader="{ isActive }">
      <v-progress-linear
        :active="isActive"
        color="deep-purple"
        height="4"
        indeterminate
      ></v-progress-linear>
    </template>

    <!-- Image Container with Styling for Uniform Header -->
    <div class="card-header-image">
      <v-img
        :src="imagePath"
        contain
        class="image-full-width"
        style="height:200px;"
      ></v-img>
    </div>

    <!-- Card Content -->
    <v-card-item>
      <v-row>
      <v-col cols="8">
      <v-card-title  class="name-text">{{ partner.name }}</v-card-title>
      <v-card-subtitle>
        <span class="location-text">{{ partner.location }}</span>
        <v-icon color="error" icon="mdi-fire-circle" size="small"></v-icon>
      </v-card-subtitle>
    </v-col>
    <v-col cols="4" class="justify-right" style="border:1px solid #f5f5f5;">
      <a :href="partner.instagram" target="_blank" rel="noopener noreferrer">
        <img
          class="social-icon"
          src="@/assets/instagram.png"
          alt="Instagram"
        />
      </a>
    </v-col>

    </v-row>
    </v-card-item>

    <!--<v-card-text>
        <div class="my-4 text-subtitle-1">$ • Italian, Cafe</div>
        <div>
          Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.
        </div>
      </v-card-text>-->
    <v-divider class="mx-4 mb-1"></v-divider>
    <!-- Availability -->
    <!-- Card Actions -->
    <v-card-actions>
      <v-btn color="blue" style="font-weight: 600;" text block @click="reserve"
        >VISIT WEBSITE</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    partner: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    logo: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    selection: 1,
  }),
  computed: {
    imagePath() {
      try {
        return require(`@/assets/${this.logo}`);
      } catch (e) {
        console.error("Error loading image:", e);
        return "";
      }
    },
  },
  methods: {
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
  },
};
</script>

<style scoped>
.card-header-image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensures content doesn't overflow */
  border-bottom: 1px solid #e0e0e0; /* Optional border for separation */
}

.image-full-width {
  width: 100%; /* Ensures image is full width */
  height: auto; /* Maintains aspect ratio */
  max-height: 100%; /* Ensures image does not exceed the container height */
}

.social-icon {
  width: 60px;
  height: 60px;
  margin-left:1px;
  animation:fadeOut 2s forwards;
  animation-timeline: scroll;
}

.social-icon:hover, .linkText:hover {
  transform: scale(1.1);
  opacity: .85;
}

.location-text{
  font-size: 14px;
  font-weight: 500;
}

.name-text{
  color:#006df2;
  font-size: 18px;
}
</style>
