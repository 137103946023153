<template>
  <div>
  <PasswordScreen v-if="isLocked" @unlocked="handleUnlock" />
  <!-- Full-width Header -->
  <div v-if="!isLocked">
  <v-container fluid class="pa-0 full-width-header">
    <div class="logo-row" style="margin-top: -20px" v-scroll-animation>
      <img src="@/assets/logo_main.png" alt="Logo" />
    </div>
    <div class="logo-row" v-scroll-animation>
      <h1>PRODUCTION & SERVICE COMPANY IN UAE</h1>
    </div>
    <v-row no-gutters v-scroll-animation>
      <v-col cols="12">
        <video loop muted playsinline autoplay class="full-width-video">
          <source src="@/assets/1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </v-col>
    </v-row>
  </v-container>

  <!-- Main Content centered using CSS Grid -->
  <v-container fluid class="main-content-container">
    <div class="content">
      <div class="social-media-row">
        <a
          v-scroll-animation-left
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="@/assets/instagram.png" alt="Instagram" />
        </a>
        <a
          v-scroll-animation-right
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="@/assets/facebook.png" alt="Facebook" />
        </a>
      </div>
      <v-row>
        <v-col cols="12">
          <div class="main-text-block">
            <br class="small-br" />
            <p v-scroll-animation>
              Welcome to our film production company, serving Dubai, Abu Dhabi,
              and the entire UAE. We're dedicated to helping you bring your
              creative projects to life. Whether you're planning to produce
              feature films, short movies, film commercials, documentaries,
              music videos, photoshoots or any sort of content, our team is
              ready to support your vision and ensure it reaches its full
              potential.
            </p>
            <br class="small-br" />
          </div>
        </v-col>
      </v-row>
      <!--<div class="logo-row" v-scroll-animation>
        <h3 class="directorsText">Introducing World-class DIRECTORS</h3>
      </div>
      <div class="logo-row">
        <div class="diplomats" v-scroll-animation>
          <a
            class="linkText diplomatsText"
            style="color: #006df2"
            target="_blank"
            href="https://www.diplomats.tv"
            >DIPLOMATS<font style="font-size: 22px; margin-bottom: 25px"
              >®</font
            ></a
          >
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              class="social-icon"
              style="margin-top: 6px; margin-left: 20px"
              src="@/assets/instagram.png"
              alt="Instagram"
            />
          </a>
        </div>
      </div>-->
      <div class="logo-row" v-scroll-animation>
        <h3 class="directorsText">MANGROVE PRODUCTION HUB</h3>
      </div>
      <div class="logo-row" v-scroll-animation>
        <h4 class="directorsText">OUR PARTNERS AROUND THE WORLD</h4>
      </div>
      <v-row class="justify-space-around">
        <!-- Adjust to use justify-space-around -->
        <v-col
          v-for="(partner, index) in partners"
          :key="partner.id"
          cols="12"
          md="2"
          class="d-flex justify-center"
          style="padding: 25px"
        >
          <PartnerCard 
            v-if="index < 2"
            :partner="partner"
            :index="index"
            :logo="partner.logo"
            v-scroll-animation-left
          />          
          <PartnerCard 
            v-else-if="index==2"
            :partner="partner"
            :index="index"
            :logo="partner.logo"
            v-scroll-animation
          />
          <PartnerCard 
            v-else
            :partner="partner"
            :index="index"
            :logo="partner.logo"
            v-scroll-animation-right
          />
        </v-col>
      </v-row>
      <v-row class="justify-space-around">
        <div
          style="
            width: 30%;
            border: 1px solid #666666;
            text-align: center;
            margin-top: 30px;
            margin-bottom: 0px;
          "
        ></div>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="main-text-block">
            <h2 v-scroll-animation>Why Choose Us?</h2>
            <br class="small-br" />
            <p v-scroll-animation>
              Local Insights, Global Standards: We combine local expertise with
              international production standards to ensure your project appeals
              to both local and global audiences.
            </p>
            <br class="small-br" />
            <h2 v-scroll-animation>Adaptable Solutions for Any Budget</h2>
            <br class="small-br" />
            <p v-scroll-animation>
              We offer tailored solutions to fit your budget, helping you
              achieve your creative goals without compromise.
            </p>
            <br class="small-br" />
            <h2 v-scroll-animation>Wide-Ranging Media Production</h2>
            <br class="small-br" />
            <p v-scroll-animation>
              Our capabilities extend from traditional film to contemporary
              digital content, embracing a variety of media to suit your needs.
            </p>
            <br class="small-br" />
            <h2 v-scroll-animation>Committed Team</h2>
            <br class="small-br" />
            <p v-scroll-animation>
              Our professionals are not only skilled but also passionate about
              film production, bringing both knowledge and innovation to your
              project.
            </p>
            <br class="small-br" />
            <h2 v-scroll-animation>
              Let’s Collaborate to Create Something Remarkable
            </h2>
            <br class="small-br" />
            <p v-scroll-animation>
              In the competitive markets of Dubai and Abu Dhabi, distinctive
              storytelling and innovative production are key. Contact us today
              to explore how we can work together to make your project a notable
              success in the Emirates.
            </p>
            <br class="small-br" />
          </div>
        </v-col>
      </v-row>
      <div class="contact-row">
        <v-row class="justify-space-around">
          <v-col
          :cols="$vuetify.display.xs ? 12 : ($vuetify.display.sm ? 6 : 3)"
            class="partner-col"
            v-scroll-animation-left
            style="margin-bottom:20px;padding-left: 15px;padding-right: 15px;"
          >
            <img class="contact-img" src="@/assets/camilo.png" alt="Camilo" />
            <p>Camilo Berriz Jacas</p>
            <p>Producer & Partner</p>
            <p><a target="_blank" style="text-decoration: none;" href="https://wa.me/381602909890"><img style="width: 25px;height:25px;margin-right:2px;transform: translateY(5px);" src="@/assets/whatsapp.png">+381602909890</a></p>
            <p>
              <a style="text-decoration: none !important;" href="mailto:camilo@mangrovefilms.com"
                >camilo@mangrovefilms.com</a
              >
            </p>
          </v-col>
          <v-col
            :cols="$vuetify.display.xs ? 12 : ($vuetify.display.sm ? 6 : 3)"
            class="partner-col"
            v-scroll-animation-right
            style="margin-bottom:20px;padding-left: 15px;padding-right: 15px;"
          >
            <img class="contact-img" src="@/assets/nelson.png" alt="Nelson" />
            <p>Nelson Navarro Navarro</p>
            <p>Ex. Producer & Partner</p>
            <p><a target="_blank" style="text-decoration: none;" href="https://wa.me/34689038202"><img style="width: 25px;height:25px;margin-right:2px;transform: translateY(5px);" src="@/assets/whatsapp.png">+34689038202</a></p>
            <p>
              <a style="text-decoration: none !important;" href="mailto:nelson@mangrovefilms.com"
                >nelson@mangrovefilms.com</a
              >
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
  <!-- Full-width Footer -->
  <v-container fluid class="pa-0 full-width-footer">
    <v-row no-gutters>
      <v-col cols="12">
        <footer
          class="footer-content"
          style="
            background-color: #3f78ec;
            height: 50px;
            text-align: center;
            margin-top: 100px;
          "
        >
          <p>Mangrove Films 2024 All Rights Reserved</p>
        </footer>
      </v-col>
    </v-row>
  </v-container>
</div>
</div>
</template>

<script>

import { onUnmounted } from "vue";
import PartnerCard from "@/components/PartnerCard.vue";
import PasswordScreen from '@/components/PasswordScreen.vue';
export default {
  directives: {
    scrollAnimation: {
      mounted(el) {
        el.classList.add("scroll-animation");

        const handleScroll = () => {
          const rect = el.getBoundingClientRect();
          if (rect.top <= window.innerHeight && rect.bottom >= 0) {
            el.classList.add("visible");
          } else {
            el.classList.remove("visible");
          }
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Initial check in case element is already in view

        // Clean up event listener on unmount
        onUnmounted(() => {
          window.removeEventListener("scroll", handleScroll);
        });
      },
    },
    scrollAnimationLeft: {
      mounted(el) {
        el.classList.add("scroll-animation-left");

        const handleScroll = () => {
          const rect = el.getBoundingClientRect();
          if (rect.top <= window.innerHeight && rect.bottom >= 0) {
            el.classList.add("visible");
          } else {
            el.classList.remove("visible");
          }
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Initial check in case element is already in view

        // Clean up event listener on unmount
        onUnmounted(() => {
          window.removeEventListener("scroll", handleScroll);
        });
      },
    },
    scrollAnimationRight: {
      mounted(el) {
        el.classList.add("scroll-animation-right");

        const handleScroll = () => {
          const rect = el.getBoundingClientRect();
          if (rect.top <= window.innerHeight && rect.bottom >= 0) {
            el.classList.add("visible");
          } else {
            el.classList.remove("visible");
          }
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Initial check in case element is already in view

        // Clean up event listener on unmount
        onUnmounted(() => {
          window.removeEventListener("scroll", handleScroll);
        });
      },
    },
  },

  components: {
    PartnerCard,
    PasswordScreen
  },
  data() {
    return {
      isLocked: true,
      partners: [
        {
          id: 1,
          name: "Island Film",
          link: "https://www.islandfilmcuba.com",
          instagram: "http://www.instagram.com",
          logo: "logo_if.png",
          location: "HAVANA, CUBA",
        },
        {
          id: 2,
          name: "Pictures Perfect",
          link: "http://pictureperfectmiami.com",
          instagram: "http://www.instagram.com",
          logo: "miami.jpg",
          location: "MIAMI, FLORIDA, USA",
        },
        {
          id: 3,
          name: "Mendips Films",
          link: "https://www.mendips.es",
          instagram: "http://www.instagram.com",
          logo: "logo_mendips.png",
          location: "BARCELONA, SPAIN",
        },
        {
          id: 4,
          name: "Cinema Siete",
          link: "https://www.cinemasiete.com",
          instagram: "http://www.instagram.com",
          logo: "cinemasiete_logo.png",
          location: "TORONTO, CANADA",
        },
        {
          id: 5,
          name: "Diplomats",
          link: "https://www.diplomats.tv",
          instagram: "http://www.instagram.com",
          logo: "logo_diplomats.png",
          location: "PARIS, FRANCE",
        },
      ],
    };
  },
  name: "HomePage",
  methods: {
    contactUs() {
      // your contact logic here
    },
    handleUnlock() {
      this.isLocked = false;
    }
  },
};
</script>

<style scoped>
.full-width-header {
  background-color: #fff;
  color: white;
}

.header-content {
  padding: 16px;
}

.main-content-container {
  display: grid;
  justify-content: center;
  padding: 0 16px;
}

.content {
  max-width: 1400px;
  width: 100%;
}

.full-width-video {
  width: 100%;
  height: auto;
}

.main-text-block {
  text-align: left;
  color: #006df2;
  padding: 16px;
  font-weight: 600;
  font-size: 22px;
  font-family: Lato, sans-serif;
  line-height: 1.2;
}

.main-text-block h2 {
  color: #0049B7;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  animation: fadeOut 2s forwards;
  animation-timeline: scroll;
}

.full-width-footer {
  background-color: #fff;
  color: white;
}

.footer-content {
  padding: 16px;
  text-align: center;
}

.social-media-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 20px;
}

.contact-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 50px 20px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.social-media-row a img {
  max-width: 150px;
  transition: transform 0.3s ease;
}

.social-media-row a img:hover {
  transform: scale(1.2);
}

.logo-row,
.full-width-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width-row img {
  max-width: 700px;
}

.logo-row img {
  max-width: 300px;
}

.logo-row h1 {
  color: #000;
  margin-top: -40px;
  margin-bottom: 25px;
  font-family: 'Bebas Neue', sans-serif;
}

.linkText {
  font-weight: 600;
}

.social-icon {
  width: 60px;
  height: 60px;
}

.social-icon:hover,
.linkText:hover {
  transform: scale(1.1);
  opacity: 0.85;
}

.directorsText {
  font-size: 35px;
  margin: 15px;
  color: #0049B7;
  font-family: 'Bebas Neue', sans-serif;
}

.logo-row h4 {
  font-size: 25px;
  margin-bottom: 20px;
  font-family: 'Bebas Neue', sans-serif;
}

.logo-row h5 {
  font-size: 20px;
  margin-bottom: 20px;
}

.fill-height {
  min-height: 100vh;
}

.small-br {
  line-height: 0;
  margin: 0;
}

.diplomats {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.partner-col {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 8px;
}

.partner-col p,
.partner-col a {
  color: #006df2;
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.scroll-animation,
.scroll-animation-left,
.scroll-animation-right {
  opacity: 0;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.scroll-animation {
  transform: translateY(30px);
}

.scroll-animation-left {
  transform: translateX(-30px);
}

.scroll-animation-right {
  transform: translateX(30px);
}

.scroll-animation.visible {
  opacity: 1;
  transform: translateY(0);
}

.scroll-animation-left.visible,
.scroll-animation-right.visible {
  opacity: 1;
  transform: translateX(0);
}

.diplomatsText {
  font-size: 44px;
}

@media only screen and (max-width: 767px) {
  .logo-row h1 {
    font-size: 22px;
    text-align: center;
    margin-top: 5px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .logo-row h4 {
    font-size: 18px;
    text-align: center;
  }
  .logo-row img {
    max-width: 150px;
    margin-top: 20px;
  }

  .social-media-row a img {
    max-width: 100px !important;
  }

  .diplomats {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .directorsText {
    font-size: 25px !important;
    text-align: center;
    margin: 0px;
  }

  .diplomatsText {
    font-size: 32px;
  }

  .main-text-block {
    font-weight: 400;
    font-size: 18px;
    font-family: Lato, sans-serif;
    line-height: 1.5;
  }
  
  .scroll-animation-left, .scroll-animation-right {
    transform: translateX(0px) !important;
    transform: translateY(30px);
  }

  .contact-row {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }
}
</style>
