<template>
    <div v-if="isLocked" class="screen-overlay">
      <div class="password-container">
        <h2>Introduce la contraseña</h2>
        <input 
          type="password" 
          v-model="password" 
          placeholder="Contraseña" 
          @keyup.enter="unlockSite"
        />
        <button @click="unlockSite">Acceder</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import CryptoJS from 'crypto-js';
  export default {
    data() {
      return {
        password: '',
        correctPasswordHash:"111e7cd54f5e2cc8f0ddd73b7e0a4d417167839bd16977e853352e57b1584945",// 'UnlockMGF24!!', // Aquí configuras tu contraseña
        errorMessage: '',
        isLocked: true // Estado para manejar el bloqueo
      };
    },
    methods: {
        unlockSite() {
        // Generar el hash de la contraseña introducida por el usuario
        const enteredPasswordHash = CryptoJS.SHA256(this.password).toString();
        // Comparar el hash generado con el hash almacenado
        if (enteredPasswordHash === this.correctPasswordHash) {
            this.isLocked = false; // Desbloquear el contenido
            this.$emit('unlocked');
        } else {
            this.errorMessage = 'Contraseña incorrecta. Intenta de nuevo.';
      }
    }
    }
  };
  </script>
  
  <style scoped>
  .screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .password-container {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
  }
  
  input[type="password"] {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  </style>
  